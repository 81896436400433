<template>
    <div class="modal-agree-like bg-main">
        <div class="noti-box">
            <div class="close-icon" @click="onClickClose()">
                <i class="material-icons i-right f-30" @click="onClickClose()">close</i>
            </div>
            <div class="title m-l-20 m-r-20 f-bold f-22">
                굿퍼슨은 진정성 있고 책임감 있는 만남 문화를 지향하며, 이를 위해 약속과 관련하여 페널티 정책을
                운영합니다.
            </div>

            <div class="content-box m-b-36 m-l-20 m-r-20">
                <div class="content">
                    <ul class="noti">
                        <div class="noti-title f-bold f-17 m-b-12">1. 매칭권 사용 안내</div>
                        <li class="m-l-30 noti-list f-medium f-15">
                            회원님과 상대방이 서로 수락하는 즉시 약속 잡기가 시작됩니다.
                        </li>
                        <li class="m-l-30 noti-list f-medium f-15">
                            약속을 잡을 때 매칭권(유료)이 필요합니다.
                            <div class="click-ticketpage text-underline f-bold" @click="onClickTicketPage">
                                (가격 보기)
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="content">
                    <ul class="noti">
                        <div class="noti-title f-bold f-17 m-b-12">2. 미진행/취소수수료 안내</div>
                        <li class="m-l-30 noti-list f-medium f-15">
                            약속 잡기 시작 후 약속 잡기를 그만둔 경우
                            <br />
                            <span class="f-bold">→</span> 약속 미진행 수수료
                            <span class="f-bold c-red text-underline">2만원 부과</span>
                        </li>
                        <li class="m-l-30 noti-list f-medium f-15">
                            약속이 확정된 후 약속을 취소할 경우
                            <br />
                            <span class="f-bold">→</span> 취소 수수료
                            <span class="f-bold c-red text-underline">5만원 부과</span>
                        </li>
                        <li class="m-l-30 noti-list f-medium f-15">
                            부득이한 사유가 있는 경우 수수료가 면제됩니다.
                            <div class="click-ticketpage text-underline f-bold" @click="onClickDetails">
                                (자세히 보기)
                            </div>
                        </li>
                    </ul>
                </div>
                <!-- <div class="content center">
                    약속 취소/변경 관련 페널티
                    <span class="open-inapp f-bold" @click="onClickDetails">자세히 보기(클릭)</span>
                </div> -->
            </div>
            <div class="hr"></div>
            <!-- <div class="check">
                <img :src="notibox" alt="noti-box" width="24px" height="24px" @click="onClickCheck" />
                <div class="noti-content m-l-8">약속 취소/변경 관련 페널티 내용에 동의합니다.</div>
            </div> -->
            <div class="agree-box m-l-20 m-r-20 m-t-20 f-14 f-medium">
                내용 확인 후에 아래 텍스트를 그대로 따라서 입력해주세요.
                <div class="text-box m-t-12 m-b-12 p-12">ℹ️ {{ sampleText }}</div>
                <TextareaWithX v-model="agreeText" :placeholder="placeholder" :isInputMode="true" />
            </div>

            <div class="button-container m-t-20 p-b-10">
                <button class="next-btn f-18" @click="onClickNext">동의하고 수락하기</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalAgreeLike',
    props: ['options'],
    data: () => ({
        checked: false,
        agreeText: null,
    }),
    computed: {
        me() {
            return this.$store.getters.me
        },
        notibox() {
            if (this.checked === true) {
                return require('@/assets/images/icons/notibox_active.png')
            } else {
                return require('@/assets/images/icons/notibox_inactive.png')
            }
        },
        status() {
            return this.$store.getters.me.profile.status
        },
        sampleText() {
            return '매칭권 사용과 수수료 정책에 동의합니다.'
        },
        placeholder() {
            return '위 텍스트를 그대로 입력해주세요'
        },
    },
    methods: {
        onClickClose() {
            this.$emit('close')
        },
        onClickCheck() {
            this.checked = !this.checked
        },
        onClickDetails() {
            this.$emit('close')
            this.$stackRouter.push({
                name: 'FeeInformationPage',
            })
        },
        async onClickTicketPage() {
            this.$emit('close')
            // await this.$stackRouter.pop()
            await this.$stackRouter.push({ name: 'PrePaymentPage', props: { from: 'modalAgreeLike' } })
        },
        async onClickNext() {
            const trimmedText = this.agreeText.trim().replace(/\./g, '')
            const trimmedSampleText = this.sampleText.trim().replace(/\./g, '')

            if (!this.agreeText) {
                this.$modal.basic({
                    body: '약속 미진행 및 취소 수수료 정책을 확인하고 동의해주세요.',
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
            } else if (trimmedText !== trimmedSampleText) {
                this.$modal.basic({
                    body: '동의 텍스트를 잘못 입력했습니다. 오타, 띄어쓰기를 확인해주세요.',
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
            } else {
                if (this.status === 'created') {
                    const idx = await this.$modal.custom({
                        component: 'ModalTitleBolded',
                        options: {
                            title: '프로필을 완성해주세요.',
                            body:
                                '프로필 입력과 서류 인증이 마무리되면, 지금 선택하신 분과의 소개팅 주선을 진행해드립니다.',
                            buttons: [
                                {
                                    label: '프로필 입력하기',
                                    style: {
                                        bgColor: '#03006E',
                                        color: '#FFFFFF',
                                        border: 'none',
                                    },
                                },
                            ],
                            height: '48px',
                        },
                    })
                    if (idx === 0) {
                        this.$emit('close')
                        await this.$stackRouter.pop()
                        await this.$router.push({ name: 'ChatsPage' })
                        setTimeout(() => {
                            this.$stackRouter.push({
                                name: 'ProfileSteps',
                            })
                        }, 200)
                    }
                } else if (this.status === 'complete_profile' || this.status === 'ongoing_verification') {
                    const idx = await this.$modal.custom({
                        component: 'ModalTitleBolded',
                        options: {
                            title: '서류 인증을 완료해주세요.',
                            body:
                                '서류 인증 이후, 지금 선택하신 분과의 소개팅 주선을 진행해드립니다. <br />(참고 - 대학교 졸업증명서의 경우 대리 발급 서비스를 제공하고 있어요!)',
                            buttons: [
                                {
                                    label: '서류 인증하기',
                                    style: {
                                        bgColor: '#03006E',
                                        color: '#FFFFFF',
                                        border: 'none',
                                    },
                                },
                            ],
                            height: '48px',
                        },
                    })
                    if (idx === 0) {
                        this.$emit('close')
                        await this.$stackRouter.pop()
                        await this.$router.push({ name: 'ChatsPage' })
                        setTimeout(() => {
                            this.$stackRouter.push({
                                name: 'CertificatePage',
                            })
                        }, 200)
                    }
                } else if (this.status === 'judging') {
                    const idx = await this.$modal.custom({
                        component: 'ModalTitleBolded',
                        options: {
                            title: '서류 심사가 진행중입니다.',
                            body:
                                '운영팀에서 서류를 심사중이에요.<br/>서류 심사가 완료되면 지금 선택하신 분과의 소개팅 주선을 진행해드립니다. 잠시만 기다려주세요 :)',
                            buttons: [
                                {
                                    label: '확인',
                                    style: {
                                        bgColor: '#03006E',
                                        color: '#FFFFFF',
                                        border: 'none',
                                    },
                                },
                            ],
                            height: '48px',
                        },
                    })
                    if (idx === 0) {
                        this.$emit('close')
                    }
                } else if (this.status === 'judged') {
                    const idx = await this.$modal.custom({
                        component: 'ModalTitleBolded',
                        options: {
                            title: '매니저가 배정되지 않았습니다.',
                            body:
                                '매니저 배정 중이에요. <br/>매니저 배정 이후에 선택해주신 분과의 소개팅 주선을 진행해드립니다. 잠시만 기다려주세요 :)',
                            buttons: [
                                {
                                    label: '확인',
                                    style: {
                                        bgColor: '#03006E',
                                        color: '#FFFFFF',
                                        border: 'none',
                                    },
                                },
                            ],
                            height: '48px',
                        },
                    })
                    if (idx === 0) {
                        this.$emit('close')
                    }
                } else {
                    const msg = 'agree'
                    this.$emit('close', msg)
                }
            }
        },
        onScroll(event) {
            this.showHiddenHeader = event.target.scrollTop > 56 // 56 is @header-height
        },
    },
}
</script>
<style scoped lang="scss">
.modal-agree-like {
    position: relative;
    display: flex;
    justify-content: center;

    // .noti-box {
    //     margin: 0 20px;
    // }

    .close-icon {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .title {
        margin-top: 80px;
        line-height: 33px;
        // text-align: center;
        color: #181f39;
    }

    .content {
        margin-top: 30px;
        font-size: 17px;
        line-height: 25.5px;
        text-align: left;
        color: #787878;

        @media screen and (max-height: 700px) {
            font-size: 15px;
        }

        .noti-title {
            color: #151360;
        }

        &.center {
            text-align: center;
        }

        li {
            list-style-type: disc;
            // margin-left: 20px;
        }

        .open-inapp {
            margin-left: 7px;
            color: #151360;
        }
        .red {
            color: #f64f56;
        }
    }

    .hr {
        height: 4px;
        flex-shrink: 0;
        background: #faf0e1;
    }

    .agree-box {
        .text-box {
            border-radius: 8px;
            border: 1px solid rgba(92, 92, 119, 0.5);
            background: $grey-01;
        }
    }

    .next-btn {
        @include center;
        margin: auto;
        width: 248px;
        height: 48px;
        color: #f7f7f4;
        background: #151360;
        border-radius: 36px;
        border: none;
    }
}
</style>
